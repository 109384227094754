@use '~/src/sass/abstracts/utils' as *;
@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/typography' as typo;
@use '~/src/sass/abstracts/variables/structure';

.lvp {
	background-color: colors.$grey-100;
	border-radius: 20px;
}

.lvp__wrapper {
	padding: 3rem;
	display: grid;
	grid-gap: 3rem;
	font-family: typo.$secondary;
	align-items: center;
}

.lvp .lvp__title {
	font-size: 3.4rem;
	margin-bottom: 1rem;
	font-weight: 700;
}

.lvp__text {
	@extend %font-md;
}

.btn.btn--round.lvp__link {
	border-color: colors.$grey-200;
	transition: background-color 500ms ease-in-out;
	i {
		color: colors.$grey-200;
	}

	&:focus,
	&:active,
	&:hover {
		background-color: colors.$grey-400;

		i {
			color: colors.$white;
		}
	}
}

@media screen and (min-width: structure.$tablet-portrait) {
	.lvp__wrapper {
		grid-template-columns: repeat(2, minmax(1rem, 1fr));
	}
}
